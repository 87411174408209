
import axios from 'axios';
export default {
    
    async createOffDays(params)  {
        return await axios.post(`off_days/create` , params)
    },
    async createOffDaysList(params)  {
        return await axios.post(`off_days/create/list` , params)
    },
    async updateOffDaysColumn(column , value , data)  {
        return await axios.put(`off_days/update_list?${column}=${value}` , data)
    },
    async deleteOffDaysList(list)  {
        return await axios.delete(`off_days/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportOffDays(column , value)  {
        return await axios.get(`off_days/report?${column}=${value}`)
    },
    async getAllOffDays()  {
        return await axios.get(`off_days/all`)
    },
    async getOneOffDays(off_day_id)  {
        return await axios.get(`off_days/all/${off_day_id}`)
    },
    async getOffDaysByColumn(column , value)  {
        return await axios.get(`off_days/filter?column=${column}&value=${value}`)
    },
    async deleteOffDays(off_day_id)  {
        return await axios.delete(`off_days/delete/${off_day_id}`)
    },
    async updateOffDays(off_day_id , params)  {
        return await axios.put(`off_days/update/${off_day_id}` , params)
    }
}